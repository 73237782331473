import { render, staticRenderFns } from "./word.vue?vue&type=template&id=b7927d70&scoped=true&"
import script from "./word.vue?vue&type=script&lang=js&"
export * from "./word.vue?vue&type=script&lang=js&"
import style0 from "./word.vue?vue&type=style&index=0&id=b7927d70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7927d70",
  null
  
)

export default component.exports