<template>
  <div
    class="word"
    style="
      position: absolute;
      width: 40%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      left: 50%;
      padding: 30px;
      transform: translateX(-50%);
      font-family: '宋体';
      letter-spacing: 2px;
      line-height: 30px;
    "
  >
    <div class="fiexd" v-show="this.pageInfo.pdfUrl" @click="download">
      签约成功,点击下载PDF版文件
    </div>
    <div class="html">
      <h2 class="header" style="text-align: center; margin-bottom: 30px">
        {{ pageInfo.contractName }}
      </h2>
      <div class="row" style="margin: 10px 0">
        <strong>签约方一：</strong>
        <span
          v-if="!this.pageInfo.partyaName"
          style="color: blue; cursor: pointer"
          @click="dialogVisible = true"
          >点击签约</span
        >
        <span v-else>
          {{ this.pageInfo.partyaName }}（{{ this.pageInfo.partyaIdnumber }}）
        </span>
      </div>
      <div class="row" style="margin: 10px 0">
        <strong>签约方二：</strong>
        <span>深圳写手智能科技公司（9144232323232323232）</span>
      </div>
      <div class="row" style="margin: 10px 0">
        <strong>地址：</strong>
        <span>深圳市南山区科技南十二路12号曙光大厦605-608</span>
      </div>
      <div class="row text" style="margin: 20px 0; text-indent: 2em">
        为促进双方在AI成文、专业服务方面的紧密合作，规范双方诚信履行保密义务、保护双方商业秘密，防止不当或恶意公开及泄漏事件发生，根据中华人民共和国相关法律及国务院有关部委规定，双方本着平等、自愿、公平和诚实信用的原则签订本商业秘密保护协议。
      </div>
      <h3 class="text" style="text-indent: 2em">一、商业秘密的范围</h3>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        1.本协议所称“商业秘密”包括但不限于技术信息、专有技术、客户信息、内部秘密等，包括但不限于版权数据、思维模型、逻辑架构、产品技术方案、框架设计、技术指标、数据库、技术报告、技术文档相关的函电等方面的秘密。
      </div>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        2.签约方一的商业秘密主要是为支持“AI成文”“专业服务”两项产品商业化运行，所提供版权数据、数据集、思维模型、指标条件、逻辑推理规则、结果方案等。
      </div>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        3.签约方二的商业秘密主要是为支持“AI成文”“专业服务”两项产品的架构编码，所提供的AI语言模型、调优调参方式、数据增补标注及其实现方式等。
      </div>
      <h3 class="text" style="text-indent: 2em">二、商业秘密的载体</h3>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        1.双方载有商业秘密信息的文件、资料、图表、笔记、报告、信件、磁盘、硬盘、电子邮件以及其它任何形式的载体均属于保护范围。
      </div>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        2.未经双方许可，不得擅自对前述载体进行复制，也不得将载体或者复制件遗弃、怠于保管或者交给他人，更不得以任何方式公之与众。
      </div>
      <h3 class="text" style="text-indent: 2em">三、保密制度</h3>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        1.双方应当遵守国家与保密相关的法律、法规及双方的协议约定。
      </div>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        2.双方应当严格遵守双方的任何成文或者不成文的保密制度，并履行相应保密职责。
      </div>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        3.双方保密制度没有规定或者规定不明确之处，亦应本着谨慎、诚信、负责的态度，采取必要和合理的措施，保守其知悉或者持有的商业秘密。。
      </div>
      <h3 class="text" style="text-indent: 2em">四、保密责任</h3>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        1.未经书面同意，不得泄露、告知、公布、发表、出版、传播、传授、转让或者以遗弃或者怠于保管等其它任何方式使任何第三方知悉的商业秘密，也不得在履行合作之外使用这些商业秘密。
      </div>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        2.双方合作期间乃至合作终止后，均应对其接触、知悉的商业秘密，承担保密义务，直到宣布解密或者有关秘密信息实际已经公开为止。
      </div>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        3.双方不得擅自使用任何属于他方的商业秘密，也不得擅自实施可能侵犯他人知识产权的行为。否则导致遭受第三人侵权指控，因此而承担侵权赔偿责任的，有权向违约方追偿。
      </div>
      <h3 class="text" style="text-indent: 2em">五、违约责任及争议解决</h3>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        1.违反本协议造成损失的，依法对守约方赔偿100万元。违约方承担违约赔偿责任并不免除其继续按照本协议承担保密义务。
      </div>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        2.因履行本协议发生争议，由双方协商解决。
      </div>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        3.协商不成的，向深圳市前海合作区人民法院诉讼解决。
      </div>
      <h3 class="text" style="text-indent: 2em">六、其他事项</h3>
      <div class="row text" style="margin: 10px 0; text-indent: 2em">
        本协议正本一式两份，一方一份，均具同等法律效力。签字之日起生效。
      </div>
      <div class="sign" style="width: 80%; margin: 70px auto 80px">
        <div
          class="PartyA"
          style="
            position: relative;
            width: 50%;
            display: inline-block;
            float: left;
            text-align: center;
            color: #808080;
          "
        >
          <div class="title">
            <strong>签约方一签章:</strong>
            <img
              style="width: 180px; position: absolute; top: -30px; left: 25%"
              :src="viewUrl"
              alt=""
            />
          </div>
          <div class="date" style="margin: 15px auto">
            {{ this.pageInfo.partyaDate ? this.pageInfo.partyaDate : "" }}
          </div>
          <div>签约地址: {{ address }}</div>
        </div>
        <div
          class="PartyB"
          style="
            position: relative;
            width: 50%;
            display: inline-block;
            float: left;
            text-align: center;
            color: #808080;
          "
        >
          <div class="title">
            <strong>签约方二签章:</strong>
            <img
              style="width: 180px; position: absolute; top: -30px; left: 25%"
              :src="
                this.$store.state.onlineBasePath + this.pageInfo.partybSignature
              "
              alt=""
            />
          </div>
          <div class="date" style="margin: 15px auto">
            {{
              this.pageInfo.contractName
                ? this.pageInfo.partybDate
                : "2023年 月 日"
            }}
          </div>
          <div>签约地址: 广东省深圳市南山区</div>
        </div>
      </div>
    </div>

    <el-dialog
      class="dialog"
      title="填写信息"
      :visible.sync="dialogVisible"
      center
      width="500px"
      :modal="false"
    >
      <div class="switch">
        <div
          class="individual"
          :class="{ active: currentPage == 1 }"
          @click="switchForm(1)"
        >
          个人
        </div>
        <div
          class="company"
          :class="{ active: currentPage == 2 }"
          @click="switchForm(2)"
        >
          公司
        </div>
      </div>
      <el-form ref="form" :model="formData" label-width="110px">
        <el-form-item :label="currentPage == 1 ? '姓名' : '公司'">
          <el-input
            v-model="formData.partyaName"
            style="width: 280px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="currentPage == 1 ? '身份证' : '税号'">
          <el-input
            v-model="formData.partyaIdnumber"
            style="width: 280px"
          ></el-input>
        </el-form-item>
        <el-form-item label="签章(二选一)">
          <el-upload
            style="display: inline-block; margin-right: 20px"
            class="upload-demo"
            action=""
            :show-file-list="false"
            :http-request="uploadChecking"
            ><el-button type="primary" size="mini"
              ><i class="el-icon-upload el-icon--right"></i
              >上传电子签章</el-button
            >
          </el-upload>
          <el-button type="primary" size="mini" @click="signaTureShow = true"
            ><i class="el-icon-edit el-icon--right"></i>手动签名</el-button
          >
        </el-form-item>
      </el-form>
      <img
        :src="viewUrl"
        style="width: 100px; display: block; margin: 0 auto"
        alt=""
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="confirm" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 签名画布组件 -->
    <el-dialog
      title="签名"
      :modal="false"
      :visible.sync="signaTureShow"
      width="90%"
    >
      <Signature
        class="SignatureCanvas"
        :width="700"
        :height="300"
        @handleSubmit="handleSignature"
      />
    </el-dialog>
  </div>
</template>

<script>
import { upload, findContract, signContract, verifyIdNumber,findContractByCode } from "api/common";
import axios from "axios";
import { wgs84togcj02 } from "./geolocation";
import Signature from "components/Signature";
import qs from 'qs';
export default {
  components: {
    Signature,
  },
  created() {
    if(this.$route.query.code){
      this.initWord(this.$route.query.code);
    }
  },
  mounted() {},
  data() {
    return {
      dialogVisible: false,
      signaTureShow: false,
      viewUrl: "",
      pageInfo: {},
      formData: {},
      currentPage: 1,
      longitude: "",
      latitude: "",
      address: "",
    };
  },
  methods: {
    /* el-upload 自定义文件上传 */
    async uploadChecking(files) {
      const { file } = files;
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      var fromData = new FormData();
      fromData.append("file", file);
      const res = await upload(fromData);
      if (res.code == 200) {
        this.viewUrl = this.$store.state.onlineBasePath + res.data;
        this.formData.partyaSignature = res.data;
      }
    },
    switchForm(value) {
      this.currentPage = value;
      this.formData.partyaName = "";
      this.formData.partyaIdnumber = "";
    },
    download() {
      window.open(
        this.$store.state.onlineBasePath +
          this.pageInfo.pdfUrl +
          "?attname=" +
          this.pageInfo.contractName +
          ".pdf"
      );
    },
    // 签名组件方法
    handleSignature(val) {
      this.signaTureShow = false;
      this.viewUrl = val;
      this.formData.partyaSignature = val;
    },
    async confirm() {
      if (!this.formData.partyaSignature) {
        this.$message.error("请上传签章或签名");
        return;
      }
      if (this.currentPage == 1) {
        var id =
          /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;

        if (!id.test(this.formData.partyaIdnumber)) {
          this.$message.error("请输入正确的身份证号");
          return;
        }

        const data = {
          name: this.formData.partyaName,
          idCardNumber: this.formData.partyaIdnumber,
        };
        const is = await verifyIdNumber(data);
        if (!is) {
          this.$message.error("身份证号与输入的姓名不匹配");
          return;
        }
      }
      const date = new Date();
      this.pageInfo.partyaDate = `${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日 ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      this.formData.partyaDate = `${date.getFullYear()}年${
        date.getMonth() + 1
      }月${date.getDate()}日 ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      window.navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;

        const addressArr = wgs84togcj02(
          position.coords.longitude,
          position.coords.latitude
        );

        let address = addressArr.join();

        // http://restapi.amap.com/v3/geocode/regeo?key=393a932a17f735e83ae2d1fdea27b1c5&location=
        axios
          .get(
            "http://restapi.amap.com/v3/geocode/regeo?key=393a932a17f735e83ae2d1fdea27b1c5&location=" +
              address
          )
          .then((res) => {
            this.address =
              res.data.regeocode.addressComponent.province +
              res.data.regeocode.addressComponent.city +
              res.data.regeocode.addressComponent.district;
            console.log(this.address);
            this.pageInfo = this.formData;
            this.dialogVisible = false;
            this.$nextTick(async () => {
              const htmlUrl = document.querySelector(".html").innerHTML;
              const params = {
                ...this.pageInfo,
                htmlUrl,
                latitude: this.latitude,
                longitude: this.longitude,
                address: this.address,
                type: this.currentPage - 1,
              };
              const res = await signContract(params);
              if (res.code == 200) {
                this.pageInfo = JSON.parse(JSON.stringify(res.data));
                this.formData = JSON.parse(JSON.stringify(res.data));
                this.address = this.pageInfo.address
              }
            });
          });
      });
    },
    async initWord(code) {
      const params = {
        code,
      }
      const res = await findContractByCode(qs.stringify(params));
      if (res.code == 200) {
        console.log(res);

        this.pageInfo = JSON.parse(JSON.stringify(res.data));
        this.formData = JSON.parse(JSON.stringify(res.data));
        this.address = this.pageInfo.address
        if (this.pageInfo.partyaSignature.indexOf("data:image") == -1) {
          if (this.pageInfo.partyaName) {
            this.viewUrl =
              this.$store.state.onlineBasePath + this.pageInfo.partyaSignature;
          } else {
            this.viewUrl = "";
          }
        } else {
          this.viewUrl = this.pageInfo.partyaSignature;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.word {
  position: absolute;
  width: 40%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  left: 50%;
  padding: 30px;
  transform: translateX(-50%);
  font-family: "宋体";
  letter-spacing: 2px;
  line-height: 30px;
  .row {
    margin: 10px 0;
  }
  .text {
    text-indent: 2em;
  }
  ::v-deep .el-dialog {
    .switch {
      display: flex;
      justify-content: center;
      margin: 0 auto 15px;
      width: 60%;
      > div {
        flex: 1;
        text-align: center;
        cursor: pointer;
      }
      .active {
        color: #409eff;
        font-weight: 700;
      }
    }
  }
  .fiexd {
    position: sticky;
    top: 0px;
    height: 30px;
    line-height: 30px;
    width: 100%;
    background: #ff6600;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0px;
    // line-height: 20px;
  }
  .sign {
    // display: flex;
    width: 80%;
    margin: 70px auto 80px;
    .PartyA,
    .PartyB {
      position: relative;
      width: 50%;
      img {
        width: 180px;
        position: absolute;
        top: -30px;
        left: -20px;
      }
    }
  }
}
</style>